import React, { useCallback, useRef, useState } from "react";
import Gallery from "./Gallery";
import { IconContext } from "react-icons";
import { FiGithub } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";

export default function Project() {
  const aweniObserver = useRef();
  const quickObserver = useRef();
  const bwsObserver = useRef();
  const bwsMobileObserver = useRef();
  const aweniGalleryRef = useRef();
  const quickGalleryRef = useRef();
  const bwsGalleryRef = useRef();
  const bwsMobileGalleryRef = useRef();
  const portfolioGalleryRef = useRef();
  const dentrainGalleryRef = useRef();
  const academicGalleryRef = useRef();

  const [Show, setShow] = useState(false);
  const [AweniViewing, setAweniViewing] = useState(false);
  const [QuickViewing, setQuickViewing] = useState(false);
  const [BWSViewing, setBWSViewing] = useState(false);
  const [BWSMobileViewing, setBWSMobileViewing] = useState(false);

  const AweniSection = useCallback((node) => {
    if (aweniObserver.current) aweniObserver.current.disconnect();
    aweniObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setAweniViewing(true);
      }
    });
    if (node) aweniObserver.current.observe(node);
  });

  const QuickSection = useCallback((node) => {
    if (quickObserver.current) quickObserver.current.disconnect();
    quickObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setQuickViewing(true);
      }
    });
    if (node) quickObserver.current.observe(node);
  });

  const BWSSection = useCallback((node) => {
    if (bwsObserver.current) bwsObserver.current.disconnect();
    bwsObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setBWSViewing(true);
      }
    });
    if (node) bwsObserver.current.observe(node);
  });

  const BWSMobileSection = useCallback((node) => {
    if (bwsMobileObserver.current) bwsMobileObserver.current.disconnect();
    bwsMobileObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setBWSMobileViewing(true);
      }
    });
    if (node) bwsMobileObserver.current.observe(node);
  });

  const handleOpenGallery = (Ref) => {
    Ref.current.firstChild.firstChild.click();
  };

  return (
    <section
      id="projects-section"
      className="w-full max-w-[1200px] pt-32 pb-24"
    >
      <h2 className="text-zinc-500 px-10">// Projects</h2>
      <div className="w-full flex flex-col mt-14 items-center">
        <div
          ref={AweniSection}
          className={`flex items-center justify-center w-full mb-28 flex-wrap px-5 md:px-0 transition-all duration-1000 ${
            AweniViewing
              ? "opacity-1 translate-x-0"
              : "opacity-0 translate-x-72"
          }`}
        >
          <div className="w-full md:w-[50%] order-2 md:order-1 relative group cursor-pointer rounded-lg overflow-hidden shadow-lg">
            <div className="absolute inset-0 bg-secondary/[35%] z-10 group-hover:hidden opacity-100 transition-all "></div>
            <div ref={aweniGalleryRef}>
              <Gallery
                galleryID="aweni-gallery"
                className="grayscale"
                images={[
                  {
                    largeURL: "/aweni.png",
                    thumbnailURL: "/aweni.png",
                    width: 1366,
                    height: 768,
                  },
                  {
                    largeURL: "/3aweni/3aweni.png",
                    width: 1872,
                    height: 6049,
                  },
                  {
                    largeURL: "/3aweni/9.png",
                    width: 1872,
                    height: 4115,
                  },
                  {
                    largeURL: "/3aweni/2.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/7.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/11.png",
                    width: 1870,
                    height: 1580,
                  },
                  {
                    largeURL: "/3aweni/16.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/10.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/1.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/14.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/6.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/3.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/4.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/5.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/8.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/12.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/13.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/3aweni/15.jpg",
                    width: 1347,
                    height: 927,
                  },
                ]}
              />
            </div>
          </div>
          <div className="w-full md:w-[40%] order-1 my-5 md:my-0 flex flex-col justify-start md:justify-end items-start md:items-end">
            <h3 className="text-xs text-secondary self-start pl-0 md:pl-3">
              Personal project
            </h3>
            <h3 className="text-white text-2xl mb-3 self-start pl-0 md:pl-3">
              Aweni
            </h3>
            <div className="bg-primary_dark rounded-md w-full md:w-[110%] h-fit text-white text-sm z-20 px-5 py-3 shadow-card">
              <span className="text-secondary">&#123;"</span> Developing and
              maintaining a crowdfunding website
              <span className="text-secondary"> "&#125;</span>
            </div>
            <div className="flex gap-3 text-white w-fit self-start md:self-end mt-5 h-fit flex-wrap text-xs pl-0 md:pl-3">
              <span>Typescript</span>
              <span>ReactJS</span>
              <span>Tailwind CSS</span>
              <span>Express.js</span>
              <span>MongoDB</span>
              <span>Socket.io</span>
              <span>Chart.js</span>
            </div>
            <div className="flex gap-3 items-center mt-3 self-start md:pl-3">
              <button
                className="px-3 py-1 border border-secondary text-secondary rounded text-sm hover:bg-secondary hover:text-primary self-start ml-0 md:ml-3"
                onClick={() => handleOpenGallery(aweniGalleryRef)}
              >
                View more
              </button>
              <a
                href="https://github.com/oussamadhraief/3aweni"
                target="_blank"
                className="rounded text-secondary text-sm"
              >
                <IconContext.Provider
                  value={{ className: "w-5 h-5 text-secondary" }}
                >
                  <FiGithub />
                </IconContext.Provider>
              </a>
              <a
                href="https://3aweni.vercel.app"
                target="_blank"
                className="rounded text-secondary text-sm"
              >
                <IconContext.Provider
                  value={{ className: "w-5 h-5 text-secondary" }}
                >
                  <HiOutlineExternalLink />
                </IconContext.Provider>
              </a>
            </div>
          </div>
        </div>

        <div
          ref={QuickSection}
          className={`flex items-center w-full mb-28 flex-wrap px-5 md:px-0 justify-center transition-all duration-1000 ${
            QuickViewing
              ? "opacity-1 translate-x-0"
              : "opacity-0 -translate-x-72"
          }`}
        >
          <div className="w-full md:w-[40%] order-1 my-5 md:my-0 flex flex-col justify-start items-start">
            <h3 className="text-xs text-secondary">Internship project</h3>
            <h3 className="text-white text-2xl mb-3">Quick Medical Services</h3>
            <div className="bg-primary_dark rounded-md w-full md:w-[110%] h-fit text-white text-sm z-20 px-5 py-3 shadow-card">
              <span className="text-secondary">&#123;"</span> Developing and
              maintaining a website for the sale of medical and surgical
              instruments
              <span className="text-secondary"> "&#125;</span>
            </div>
            <div className="flex gap-3 text-white w-full pr-3 mt-5 h-fit flex-wrap text-xs">
              <span>Next.js</span>
              <span>ReactJS</span>
              <span>Tailwind CSS</span>
              <span>Node.js</span>
              <span>MongoDB</span>
              <span>Next-Auth.js</span>
            </div>
            <div className="flex gap-3 items-center mt-3 self-start md:pl-3">
              <button
                className="px-3 py-1 border border-secondary text-secondary rounded text-sm hover:bg-secondary hover:text-primary"
                onClick={() => handleOpenGallery(quickGalleryRef)}
              >
                View more
              </button>
              <a
                href="https://github.com/oussamadhraief/quick-medical-services-ecommerce"
                target="_blank"
                className="rounded text-secondary text-sm"
              >
                <IconContext.Provider
                  value={{ className: "w-5 h-5 text-secondary" }}
                >
                  <FiGithub />
                </IconContext.Provider>
              </a>
            </div>
          </div>

          <div className="w-full md:w-[50%] order-2 relative group cursor-pointer rounded-lg overflow-hidden shadow-lg">
            <div className="absolute inset-0 bg-secondary/[35%] z-10 group-hover:hidden opacity-100 transition-all "></div>
            <div ref={quickGalleryRef}>
              <Gallery
                galleryID="quick-gallery"
                className="grayscale"
                images={[
                  {
                    largeURL: "/quick.png",
                    thumbnailURL: "/quick.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/quick.png",
                    width: 1871,
                    height: 5374,
                  },
                  {
                    largeURL: "/quick/1.png",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/quick/2.png",
                    width: 1341,
                    height: 735,
                  },
                  {
                    largeURL: "/quick/3.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/4.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/5.png",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/6.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/7.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/8.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/9.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/10.png",
                    width: 1378,
                    height: 689,
                  },
                  {
                    largeURL: "/quick/11.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/12.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/quick/13.jpg",
                    width: 1397,
                    height: 727,
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div
          ref={BWSSection}
          className={`flex items-center w-full mb-28 flex-wrap px-5 md:px-0 justify-center transition-all duration-1000 ${
            BWSViewing ? "opacity-1 translate-x-0" : "opacity-0 translate-x-72"
          }`}
        >
          <div className="w-full md:w-[50%] order-2 md:order-1 relative group cursor-pointer rounded-lg overflow-hidden shadow-lg">
            <div className="absolute inset-0 bg-secondary/[35%] z-10 group-hover:hidden opacity-100 transition-all "></div>
            <div ref={bwsGalleryRef}>
              <Gallery
                galleryID="bws-gallery"
                className="grayscale"
                images={[
                  {
                    largeURL: "/bws/1.jpg",
                    thumbnailURL: "/bws/1.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/bws/2.jpg",
                    width: 700,
                    height: 360,
                  },
                  {
                    largeURL: "/bws/3.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/bws/4.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/bws/5.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/bws/6.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/bws/7.jpg",
                    width: 1397,
                    height: 727,
                  },
                ]}
              />
            </div>
          </div>

          <div className="w-full md:w-[40%] order-1 my-5 md:my-0 flex flex-col justify-start md:justify-end items-start md:items-end">
            <h3 className="text-xs text-secondary self-start pl-0 md:pl-3">
              Internship project
            </h3>
            <h3 className="text-white text-2xl mb-3 self-start pl-0 md:pl-3">
              Be Wireless Solutions
            </h3>
            <div className="bg-primary_dark rounded-md w-full md:w-[110%] h-fit text-white text-sm z-20 px-5 py-3 shadow-card">
              <span className="text-secondary">&#123;"</span> Developing and
              maintaining a tank management and tracking application
              <span className="text-secondary"> "&#125;</span>
            </div>
            <div className="flex gap-3 text-white w-fit self-start md:self-end mt-5 h-fit flex-wrap text-xs pl-0 md:pl-3">
              <span>Typescript</span>
              <span>Angular</span>
              <span>Tailwind CSS</span>
              <span>DevExtreme</span>
              <span>Quarkus</span>
              <span>Keycloak</span>
              <span>Leaflet</span>
              <span>PostgreSQL</span>
            </div>
            <div className="flex gap-3 items-center mt-3 self-start md:pl-3">
              <button
                className="px-3 py-1 border border-secondary text-secondary rounded text-sm hover:bg-secondary hover:text-primary self-start ml-0 md:ml-3"
                onClick={() => handleOpenGallery(bwsGalleryRef)}
              >
                View more
              </button>
              <a
                href="https://github.com/oussamadhraief/bws"
                target="_blank"
                className="rounded text-secondary text-sm"
              >
                <IconContext.Provider
                  value={{ className: "w-5 h-5 text-secondary" }}
                >
                  <FiGithub />
                </IconContext.Provider>
              </a>
            </div>
          </div>
        </div>

        <div
          ref={BWSMobileSection}
          className={`flex items-center w-full mb-28 flex-wrap px-5 md:px-0 justify-center transition-all duration-1000 ${
            QuickViewing
              ? "opacity-1 translate-x-0"
              : "opacity-0 -translate-x-72"
          }`}
        >
          <div className="w-full md:w-[40%] order-1 my-5 md:my-0 flex flex-col justify-start items-start">
            <h3 className="text-xs text-secondary">Internship project</h3>
            <h3 className="text-white text-2xl mb-3">Be Wireless Solutions</h3>
            <div className="bg-primary_dark rounded-md w-full md:w-[110%] h-fit text-white text-sm z-20 px-5 py-3 shadow-card">
              <span className="text-secondary">&#123;"</span> Developing and
              maintaining a mobile application for maintenance staff to create
              and manage interventions
              <span className="text-secondary"> "&#125;</span>
            </div>
            <div className="flex gap-3 text-white w-full pr-3 mt-5 h-fit flex-wrap text-xs">
              <span>Ionic</span>
              <span>ReactJS</span>
              <span>Tailwind CSS</span>
              <span>DevExtreme</span>
              <span>Node.js</span>
              <span>Express.js</span>
              <span>MongoDB</span>
            </div>
            <div className="flex gap-3 items-center mt-3 self-start md:pl-3">
              <button
                className="px-3 py-1 border border-secondary text-secondary rounded text-sm hover:bg-secondary hover:text-primary"
                onClick={() => handleOpenGallery(bwsMobileGalleryRef)}
              >
                View more
              </button>
              <a
                href="https://github.com/oussamadhraief/technicien"
                target="_blank"
                className="rounded text-secondary text-sm"
              >
                <IconContext.Provider
                  value={{ className: "w-5 h-5 text-secondary" }}
                >
                  <FiGithub />
                </IconContext.Provider>
              </a>
            </div>
          </div>

          <div className="w-full md:w-[50%] order-2 relative group cursor-pointer rounded-lg overflow-hidden shadow-lg">
            <div className="absolute inset-0 bg-secondary/[35%] z-10 group-hover:hidden opacity-100 transition-all "></div>
            <div ref={bwsMobileGalleryRef}>
              <Gallery
                galleryID="bws-mobile-gallery"
                className="grayscale"
                images={[
                  {
                    largeURL: "/bws-mobile.jpg",
                    thumbnailURL: "/bws-mobile.jpg",
                    width: 1397,
                    height: 727,
                  },
                  {
                    largeURL: "/mobile/2.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/3.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/4.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/5.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/6.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/7.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/8.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/9.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/10.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/11.jpg",
                    width: 1397,
                    height: 737,
                  },
                  {
                    largeURL: "/mobile/12.jpg",
                    width: 1397,
                    height: 737,
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div
          className={`w-full h-fit ${
            Show ? "max-h-[2000px]" : "max-h-0"
          } transition-all duration-200 overflow-hidden`}
        >
          <div className="flex items-center w-full mb-28 flex-wrap px-5 md:px-0 justify-center">
            <div className="w-full md:w-[50%] order-2 md:order-1 relative group cursor-pointer rounded-lg overflow-hidden shadow-lg">
              <div className="absolute inset-0 bg-secondary/[35%] z-10 group-hover:hidden opacity-100 transition-all "></div>
              <div ref={dentrainGalleryRef}>
                <Gallery
                  galleryID="doctor-gallery"
                  className="grayscale"
                  images={[
                    {
                      largeURL: "/doctor.jpg",
                      thumbnailURL: "/doctor.jpg",
                      width: 1397,
                      height: 727,
                    },
                    {
                      largeURL: "/doctor/doctor.png",
                      width: 1864,
                      height: 5420,
                    },
                    {
                      largeURL: "/doctor/1.png",
                      width: 1397,
                      height: 727,
                    },
                    {
                      largeURL: "/doctor/2.jpg",
                      width: 1397,
                      height: 727,
                    },
                    {
                      largeURL: "/doctor/3.png",
                      width: 1397,
                      height: 727,
                    },
                    {
                      largeURL: "/doctor/4.png",
                      width: 1397,
                      height: 727,
                    },
                    {
                      largeURL: "/doctor/5.png",
                      width: 1397,
                      height: 727,
                    },
                    {
                      largeURL: "/doctor/6.png",
                      width: 1397,
                      height: 727,
                    },
                    {
                      largeURL: "/doctor/7.png",
                      width: 1397,
                      height: 727,
                    },
                    {
                      largeURL: "/doctor/8.png",
                      width: 1397,
                      height: 727,
                    },
                    {
                      largeURL: "/doctor/9.png",
                      width: 1397,
                      height: 727,
                    },
                  ]}
                />
              </div>
            </div>

            <div className="w-full md:w-[40%] order-1 my-5 md:my-0 flex flex-col justify-start md:justify-end items-start md:items-end">
              <h3 className="text-xs text-secondary self-start pl-0 md:pl-3">
                Work project
              </h3>
              <h3 className="text-white text-2xl mb-3 self-start pl-0 md:pl-3">
                The Good Doctor
              </h3>
              <div className="bg-primary_dark rounded-md w-full md:w-[110%] h-fit text-white text-sm z-20 px-5 py-3 shadow-card">
                <span className="text-secondary">&#123;"</span> Developing and
                maintaining a website for the sale of medical scrubs
                <span className="text-secondary"> "&#125;</span>
              </div>
              <div className="flex gap-3 text-white w-full pr-3 mt-5 h-fit flex-wrap text-xs pl-0 md:pl-3">
                <span>Typescript</span>
                <span>ReactJS</span>
                <span>Tailwind CSS</span>
                <span>Express.js</span>
                <span>MongoDB</span>
                <span>PassportJS</span>
                <span>Stripe</span>
              </div>
              <div className="flex gap-3 items-center mt-3 self-start md:pl-3">
                <button
                  className="px-3 py-1 border border-secondary text-secondary rounded text-sm hover:bg-secondary hover:text-primary self-start ml-0 md:ml-3"
                  onClick={() => handleOpenGallery(dentrainGalleryRef)}
                >
                  View more
                </button>
                <a
                  href="https://github.com/oussamadhraief/Dentrain"
                  target="_blank"
                  className="rounded text-secondary text-sm"
                >
                  <IconContext.Provider
                    value={{ className: "w-5 h-5 text-secondary" }}
                  >
                    <FiGithub />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`w-full h-fit ${
            Show ? "max-h-[2000px]" : "max-h-0"
          } transition-all duration-200 overflow-hidden`}
        >
          <div className="flex items-center w-full mb-28 flex-wrap px-5 md:px-0 justify-center">
            <div className="w-full md:w-[40%] order-1 my-5 md:my-0 flex flex-col justify-start items-start">
              <h3 className="text-xs text-secondary">Academic projects</h3>
              <h3 className="text-white text-2xl mb-3">
                Different snapshots of my projects
              </h3>
              <div className="bg-primary_dark rounded-md w-full md:w-[110%] h-fit text-white text-sm z-20 px-5 py-3 shadow-card">
                <span className="text-secondary">&#123;"</span> These academic
                projects, where I collaborated with classmates, showcased my web
                development skills and provided invaluable learning experiences.
                <span className="text-secondary"> "&#125;</span>
              </div>
              <div className="flex gap-3 text-white w-full pr-3 mt-5 h-fit flex-wrap text-xs">
                <span>Next.js</span>
                <span>ReactJS</span>
                <span>Tailwind CSS</span>
                <span>Node.js</span>
                <span>Express.js</span>
                <span>MongoDB</span>
                <span>HTML</span>
                <span>CSS</span>
                <span>Laravel</span>
              </div>
              <div className="flex gap-3 items-center mt-3">
                <button
                  className="px-3 py-1 border border-secondary text-secondary rounded text-sm hover:bg-secondary hover:text-primary"
                  onClick={() => handleOpenGallery(academicGalleryRef)}
                >
                  View more
                </button>
              </div>
            </div>

            <div className="w-full md:w-[50%] order-2 relative group cursor-pointer rounded-lg overflow-hidden shadow-lg">
              <div className="absolute inset-0 bg-secondary/[35%] z-10 group-hover:hidden opacity-100 transition-all "></div>
              <div ref={academicGalleryRef}>
                <Gallery
                  galleryID="academic-gallery"
                  className="grayscale"
                  images={[
                    {
                      largeURL: "/academic_projects/3.png",
                      thumbnailURL: "/academic_projects/3.png",
                      width: 1397,
                      height: 737,
                    },
                    {
                      largeURL: "/academic_projects/2.png",
                      width: 1397,
                      height: 737,
                    },
                    {
                      largeURL: "/academic_projects/4.png",
                      width: 1397,
                      height: 737,
                    },
                    {
                      largeURL: "/academic_projects/5.png",
                      width: 1397,
                      height: 737,
                    },
                    {
                      largeURL: "/academic_projects/1.png",
                      width: 1864,
                      height: 3822,
                    },
                    {
                      largeURL: "/academic_projects/6.jfif",
                      width: 1325,
                      height: 637,
                    },
                    {
                      largeURL: "/academic_projects/7.jfif",
                      width: 1325,
                      height: 637,
                    },
                    {
                      largeURL: "/academic_projects/8.jfif",
                      width: 1325,
                      height: 1050,
                    },
                    {
                      largeURL: "/academic_projects/9.jfif",
                      width: 1325,
                      height: 1052,
                    },
                    {
                      largeURL: "/academic_projects/10.jfif",
                      width: 1325,
                      height: 1204,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`w-full h-fit ${
            Show ? "max-h-[2000px]" : "max-h-0"
          } transition-all duration-200 overflow-hidden`}
        >
          <div className="flex items-center w-full mb-28 flex-wrap px-5 md:px-0 justify-center">
            <div className="w-full md:w-[50%] order-2 md:order-1 relative group cursor-pointer rounded-lg overflow-hidden shadow-lg">
              <div className="absolute inset-0 bg-secondary/[35%] z-10 group-hover:hidden opacity-100 transition-all "></div>
              <div ref={portfolioGalleryRef}>
                <Gallery
                  galleryID="portfolio-gallery"
                  className="grayscale"
                  images={[
                    {
                      largeURL: "/portfolio.jpg",
                      thumbnailURL: "/portfolio.jpg",
                      width: 1397,
                      height: 737,
                    },
                    {
                      largeURL: "/portfolio/portfolio.png",
                      width: 1864,
                      height: 3206,
                    },
                  ]}
                />
              </div>
            </div>

            <div className="w-full md:w-[40%] order-1 my-5 md:my-0 flex flex-col justify-start md:justify-end items-start md:items-end">
              <h3 className="text-xs text-secondary self-start pl-0 md:pl-3">
                Personal project
              </h3>
              <h3 className="text-white text-2xl mb-3 self-start pl-0 md:pl-3">
                Old portfolio website
              </h3>
              <div className="bg-primary_dark rounded-md w-full md:w-[110%] h-fit text-white text-sm z-20 px-5 py-3 shadow-card">
                <span className="text-secondary">&#123;"</span> Developped a
                portfolio website, you can still visit it by clicking below
                <span className="text-secondary"> "&#125;</span>
              </div>
              <div className="flex gap-3 text-white w-fit self-start mt-5 h-fit flex-wrap text-xs pl-0 md:pl-3">
                <span>Next.js</span>
                <span>ReactJS</span>
                <span>Three.js</span>
                <span>Tailwind CSS</span>
                <span>Node.js</span>
                <span>MongoDB</span>
              </div>
              <div className="flex gap-3 items-center mt-3 self-start md:pl-3">
                <button
                  className="px-3 py-1 border border-secondary text-secondary rounded text-sm hover:bg-secondary hover:text-primary"
                  onClick={() => handleOpenGallery(portfolioGalleryRef)}
                >
                  View more
                </button>
                <a
                  href="https://oussama-dhraief.vercel.app"
                  target="_blank"
                  className="rounded text-secondary text-sm"
                >
                  <IconContext.Provider
                    value={{ className: "w-5 h-5 text-secondary" }}
                  >
                    <HiOutlineExternalLink />
                  </IconContext.Provider>
                </a>
                <a
                  href="https://github.com/oussamadhraief/Portfolio-Website"
                  target="_blank"
                  className="rounded text-secondary text-sm"
                >
                  <IconContext.Provider
                    value={{ className: "w-5 h-5 text-secondary" }}
                  >
                    <FiGithub />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>

        <button
          className="w-44 border border-secondary rounded px-3 py-1 text-secondary text-sm mx-auto"
          onClick={() => setShow((prev) => !prev)}
        >
          {Show ? "View less" : "View more"}
        </button>
      </div>
    </section>
  );
}
