import React, { useCallback, useRef, useState, useEffect } from "react";

const EXPERIENCE_TABS = [
  {
    title: "Celestial wave Digital",
    path: "https://www.celestialwavedigital.com",
    role: "Frontend Developer",
    period: "October 2024 - Present",
    points: [
      "Designing responsive, visually captivating interfaces with Next.js, React, Vite, Tailwind CSS and Shadcn, creating intuitive and seamless user experiences across all devices.",
      "Integrating custom APIs and delivering interactive, data-driven visualizations that empower decision-making.",
    ],
    skills: [
      "Next.js",
      "ReactJS",
      "Vite",
      "Tailwind CSS",
      "Shadcn",
      "Chakra UI",
      "ApexCharts",
      "Recharts",
      "i18n",
    ],
  },
  {
    title: "Be Wireless Solutions",
    path: "https://bewireless-solutions.com",
    role: "Summer internship as a Mobile Developer",
    period: "August - September 2024",
    points: [
      "Developed a mobile application for maintenance staff to create and manage interventions using Ionic, React, TypeScript, TailwindCSS, Node.js, Express.js and MongoDB.",
      "Implemented functionality that allows maintenance staff to log in, view monthly statistics, add new interventions, and capture photos for reports.",
      "Created a web-based back office for administrators, enabling them to manage maintenance staff accounts, view statistics and data through dynamic charts, and monitor interventions in real-time.",
      "Improved operational efficiency by streamlining the intervention tracking process and providing a user-friendly platform for both maintenance staff and administrators.",
    ],
    skills: [
      "Ionic",
      "ReactJS",
      "Tailwind CSS",
      "DevExtreme",
      "Node.js",
      "Express.js",
      "MongoDB",
    ],
  },
  {
    title: "Be Wireless Solutions",
    path: "https://bewireless-solutions.com",
    role: "Summer internship as a Full-Stack Developer",
    period: "July - August 2023",
    points: [
      "Built a tank management and tracking application from scratch using Typescript, AngularJS, Tailwind CSS, Quarkus, Keycloak, Leaflet, and PostgreSQL.",
      "The application facilitated real-time data collection from sensors on tanks and pumps, improving inventory management for gas stations and preventing issues through an alerts system.",
      "Enhanced operational efficiency and reduced manual errors by automating the monitoring process.",
    ],
    skills: [
      "Typescript",
      "Angular",
      "Tailwind CSS",
      "DevExtreme",
      "Quarkus",
      "Keycloak",
      "Leaflet",
    ],
  },
  {
    title: "Dentrain UAE",
    path: "https://www.linkedin.com/company/dentain/",
    role: "Full-Stack Developer",
    period: "July - August 2022",
    points: [
      "Designed and developed an e-commerce website for selling medical scrubs, incorporating ReactJS, Tailwind CSS, JavaScript, Typescript, EmailJS, Node.js, MongoDB, Express.js, PassportJS, and Stripe for payment processing.",
      "The website's e-commerce functionality enabled online transactions, increasing the client's sales and operational efficiency.",
      "Provided continuous maintenance and updates to ensure the website's performance and security.",
    ],
    skills: [
      "Typescript",
      "ReactJS",
      "Tailwind CSS",
      "Express.js",
      "MongoDB",
      "PassportJS",
      "Stripe",
    ],
  },
  {
    title: "Quick Medical Services",
    path: "",
    role: "End of studies internship as a Full-Stack Developer",
    period: "February - May 2022",
    points: [
      "Developed and maintained a comprehensive website for selling medical and surgical instruments using Next.js, ReactJS, Tailwind CSS, JavaScript, Node.js, MongoDB, Next-Auth.js, and EmailJS.",
      "The website allowed users to browse a wide range of medical and surgical instruments, place orders online, and request quotations, which streamlined the client's sales process.",
      "Successfully transitioned the client from traditional transaction methods to an efficient online system, significantly improving sales operations and customer engagement.",
    ],
    skills: [
      "Next.js",
      "ReactJS",
      "Tailwind CSS",
      "Node.js",
      "MongoDB",
      "Next-Auth.js",
    ],
  },
];

const EDUCATION_TABS = [
  {
    title: "Ecole Polytechnique Sousse",
    role: "Software Engineering Degree",
    period: "2022 - Present",
  },
  {
    title: "Higher Institute of Applied Science and Technology of Sousse",
    role: "Bachelor of Computer Science",
    period: "2019 - 2022",
  },
];

export default function Biography() {
  const [activeTab, setActiveTab] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const indicatorRef = useRef();
  const sectionRef = useRef();

  // Calculate indicator positions based on tab index
  const getIndicatorPosition = (index) => {
    if (index < EXPERIENCE_TABS.length) {
      return index * 44 + 40;
    }
    return index * 44 + 100;
  };

  // Handle tab change with indicator animation
  const handleTabChange = (index) => {
    setActiveTab(index);
    if (indicatorRef.current) {
      indicatorRef.current.style.top = `${getIndicatorPosition(index)}px`;
    }
  };

  // Intersection Observer for scroll animation
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  // Render tab content based on active tab
  const renderTabContent = () => {
    if (activeTab < EXPERIENCE_TABS.length) {
      const experience = EXPERIENCE_TABS[activeTab];
      return (
        <div className="w-full simple-fade-in-animation p-4 min-h-[250px]">
          <h3>
            {experience.role}{" "}
            {experience.path ? (
              <a
                href={experience.path}
                target="_blank"
                className="text-secondary hover:underline"
              >
                @{experience.title}
              </a>
            ) : (
              <span className="text-secondary">@{experience.title}</span>
            )}
          </h3>
          <p className="text-sm text-zinc-400 mt-1 mb-6">{experience.period}</p>
          {experience.points.map((point, i) => (
            <p
              key={i}
              className="relative pl-5 text-sm text-zinc-300 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary mb-3"
            >
              {point}
            </p>
          ))}
          <div className="w-full flex flex-wrap items-center mt-7 gap-2">
            {experience.skills.map((skill, i) => (
              <p
                key={i}
                className="text-xs bg-secondary/30 text-secondary rounded p-1 w-fit"
              >
                {skill}
              </p>
            ))}
          </div>
        </div>
      );
    } else {
      const educationIndex = activeTab - EXPERIENCE_TABS.length;
      const education = EDUCATION_TABS[educationIndex];
      return (
        <div className="w-full simple-fade-in-animation p-4 min-h-[250px]">
          <h3>
            {education.role}{" "}
            <span className="text-secondary">@{education.title}</span>
          </h3>
          <p className="text-sm text-zinc-400 mt-1 mb-6">{education.period}</p>
        </div>
      );
    }
  };

  return (
    <section
      id="biography-section"
      className="relative w-full bg-primary flex flex-col items-center justify-center"
    >
      <div
        ref={sectionRef}
        className={`w-full grid max-w-[1280px] gap-8 py-10 px-5 mx-auto rounded-lg md:px-12 lg:px-16 xl:px-32 bg-transparent text-gray-100 transition-all duration-1000 ${
          isVisible ? "opacity-1 translate-y-0" : "opacity-0 translate-y-52"
        }`}
      >
        <h2 className="text-zinc-500 w-full">// Biography</h2>
        <div className="w-full flex flex-col sm:flex-row justify-start gap-5 items-start mt-10 overflow-hidden">
          <div className="w-full h-fit max-w-full sm:max-w-[220px] overflow-x-auto">
            <div className="relative w-fit max-w-fit sm:min-w-[220px] sm:max-w-[220px] sm:w-[220px] flex flex-row overflow-x-scroll hidden-scrollbar sm:overflow-hidden overflow-y-hidden sm:flex-col items-start text-white/80 border-l-0 sm:border-l-2 border-primary_dark">
              <div
                ref={indicatorRef}
                className={`hidden sm:block absolute w-1 transition-all top-10 -left-[1px] h-11 bg-secondary`}
              ></div>
              <h2 className="relative text-secondary px-5 py-2 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary before:mt-2">
                Experience
              </h2>
              {EXPERIENCE_TABS.map((tab, index) => (
                <button
                  key={index}
                  className={`py-2.5 w-fit sm:w-full text-left px-4 sm:px-2 rounded-md sm:rounded-r-md transition-all whitespace-nowrap overflow-hidden overflow-ellipsis max-w-fit sm:max-w-full ${
                    activeTab === index
                      ? "text-secondary bg-primary_dark/70"
                      : "hover:text-secondary/80 hover:translate-x-0.5 hover:-translate-y-0.5"
                  }`}
                  onClick={() => handleTabChange(index)}
                >
                  {tab.title}
                </button>
              ))}
              <h2 className="relative text-secondary px-5 py-2 before:absolute before:top-0 before:left-0.5 before:content-['▹'] before:text-secondary before:mt-2 sm:mt-5">
                Education
              </h2>
              {EDUCATION_TABS.map((tab, index) => (
                <button
                  key={index}
                  className={`py-2.5 w-fit sm:w-full text-left px-4 sm:px-2 rounded-md sm:rounded-r-md transition-all whitespace-nowrap overflow-hidden overflow-ellipsis max-w-fit sm:max-w-full ${
                    activeTab === index + EXPERIENCE_TABS.length
                      ? "text-secondary bg-primary_dark/70"
                      : "hover:text-secondary/80 hover:translate-x-0.5 hover:-translate-y-0.5"
                  }`}
                  onClick={() =>
                    handleTabChange(index + EXPERIENCE_TABS.length)
                  }
                >
                  {tab.title}
                </button>
              ))}
            </div>
          </div>
          {renderTabContent()}
        </div>
      </div>
      <div className="h-[3px] opacity-90 absolute -bottom-20 left-0 right-0 mx-auto w-32 rounded-md bg-secondary seperation-animation-1"></div>
      <div className="absolute h-[3px] rounded-md -bottom-14 right-0 left-0 mx-auto w-48 bg-secondary opacity-90 seperation-animation-2"></div>
      <div className="h-[3px] absolute opacity-90 -bottom-8 left-0 right-0 w-40 mx-auto rounded-md bg-secondary seperation-animation-3"></div>
    </section>
  );
}
